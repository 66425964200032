import React, { useMemo } from 'react';
import styles from './AdminSidebar.module.scss';
import SidebarGroup, { SidebarSection } from './SidebarGroup/SidebarGroup';
import { useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import logo from '../../../../assets/logo.png';
import cx from 'classnames';
import { motion } from 'framer-motion';
import { sidebarExtendedState } from '../../../atoms/layout/layout.atom';
import { useWindowWidth } from '@react-hook/window-size';
import UserInfo from './UserInfo/UserInfo';
import { colors } from '../../../styles/colors';
import { roleAccessState } from '../../../atoms/auth/auth.atom';
import { HttpMethod } from '../../../domain/Role';
import { companyState } from '../../../atoms/company/company.atom';
import {
  companySidebarItems,
  sidebarItems,
} from '../../../../config/sidebar/sidebar.config';

export const AdminSidebar = () => {
  const sidebarExtendedValue = useRecoilValue(sidebarExtendedState);
  const roleAccessValue = useRecoilValue(roleAccessState);
  const companyStateValue = useRecoilValue(companyState);
  const screenWidth = useWindowWidth();

  const isCollapsed = useMemo(() => {
    if (screenWidth < 1200) {
      return false;
    }

    return !sidebarExtendedValue;
  }, [sidebarExtendedValue, screenWidth]);

  const sidebarWidth = useMemo(() => {
    if (screenWidth < 1200) {
      return isCollapsed ? 88 : 320;
    }
    return isCollapsed ? 88 : 280;
  }, [isCollapsed, screenWidth]);

  const isCompanySelected = useMemo(() => {
    if (process.env.REACT_APP_PLATFORM_ENABLED !== 'true') {
      return false;
    }

    return !!companyStateValue;
  }, [companyStateValue]);

  const mergedCompanySidebarItems: SidebarSection[] = useMemo(() => {
    if (!isCompanySelected) {
      return [];
    }

    return companySidebarItems;
  }, [isCompanySelected]);

  const mergedSidebarItems: SidebarSection[] = useMemo(() => {
    return [...mergedCompanySidebarItems, ...sidebarItems].map(
      (sidebarSection: SidebarSection) => {
        const accessibleSidebarItems = sidebarSection.items.filter(
          (sidebarItem) => {
            if (sidebarItem.enabled === false) {
              return false;
            }

            if (!sidebarItem.apiUrl) {
              return true;
            }

            return roleAccessValue.some(
              (roleAccessItem) =>
                roleAccessItem.url === `/api/${sidebarItem.apiUrl}` &&
                roleAccessItem.method === HttpMethod.GET,
            );
          },
        );

        return { ...sidebarSection, items: accessibleSidebarItems };
      },
    );
  }, [roleAccessValue, mergedCompanySidebarItems]);

  return (
    <motion.div
      animate={{ width: sidebarWidth }}
      exit={{ width: 88 }}
      className={cx(styles.sidebarContainer, {
        [styles.collapsed]: isCollapsed,
      })}
      transition={{
        duration: 0.5,
        mass: 0.5,
        restDelta: 0.001,
        ease: [0, 0, 0.58, 1],
      }}
      style={{
        borderColor: colors.dividerBorderColor,
        backgroundColor: colors.backgroundColor,
      }}
    >
      <Box className={styles.sidebarContainerInner}>
        <Box data-simplebar className={styles.sidebarContainerScrollable}>
          <Link to={'/'} className={styles.logoContainer}>
            <img src={logo} />
          </Link>
          <nav className={styles.navigation}>
            {mergedSidebarItems
              .filter((sidebarSection) => sidebarSection.items.length > 0)
              .map((sidebarSection) => (
                <SidebarGroup
                  key={sidebarSection.title}
                  sidebarSection={sidebarSection}
                />
              ))}
          </nav>
          <UserInfo />
          <Box className={styles.postNavigation} />
        </Box>
      </Box>
    </motion.div>
  );
};

export default AdminSidebar;
