import React, { ComponentType, lazy, Suspense } from 'react';
import { LinearProgress as MuiLinearProgress } from '@mui/material';
import styles from './LazyLoading.module.scss';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from '../../pages/error/ErrorPage/ErrorPage';

const LazyLoad = <T extends ComponentType<any>>(
  importFunction: () => Promise<{ default: T }>,
) => {
  const Component = lazy(importFunction);

  // eslint-disable-next-line react/display-name
  return (props: React.ComponentProps<T>) => (
    <Suspense fallback={<MuiLinearProgress className={styles.lazyLoading} />}>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Component {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

export default LazyLoad;
