import React, { ReactNode, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import axios, { ErrorResponse } from '../../libraries/axios/axios';
import { apiRoutes } from '../../../config/api/apiRoutes';
import {
  clearLocalStorageValue,
  getLocalStorageValue,
  LocalStorageKey,
} from '../../utils/localStorage/localStorage';
import { toast } from 'sonner';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../atoms/translation/translation.atom';
import { translate } from '../../utils/translation/translation';

type HealthProviderProps = {
  children: ReactNode;
  onHasNewVersion: () => void;
};

export const HealthProvider = ({
  children,
  onHasNewVersion,
}: HealthProviderProps) => {
  const translations = useRecoilValue(translationsState);

  const { mutate: getVersion, data: versionResponse } = useMutation<
    { data: string },
    ErrorResponse
  >({
    mutationFn: () => {
      return axios.get(apiRoutes.health.version);
    },
  });

  useEffect(() => {
    getVersion();
  }, []);

  useEffect(() => {
    const versionValue = getLocalStorageValue(LocalStorageKey.version);

    if (versionValue) {
      toast.success(translate(translations, 'HEALTH.NEW_VERSION_LOADED'));
      clearLocalStorageValue(LocalStorageKey.version);
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getVersion();
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (
      versionResponse &&
      versionResponse.data !== process.env.REACT_APP_VERSION
    ) {
      onHasNewVersion();
    }
  }, [versionResponse?.data]);

  return <>{children}</>;
};

export default HealthProvider;
