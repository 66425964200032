import React from 'react';
import { routes } from '../routes/routes';
import { apiRoutes } from '../api/apiRoutes';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountBox from '@mui/icons-material/AccountBox';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import Speed from '@mui/icons-material/Speed';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PeopleIcon from '@mui/icons-material/People';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export const companySidebarItems = [];

export const sidebarItems = [
  {
    title: 'SIDEBAR.GROUP_GENERAL',
    items: [
      {
        title: 'SIDEBAR.DASHBOARD',
        icon: <Speed />,
        url: routes.admin.dashboard,
      },
      {
        title: 'SIDEBAR.TASKS',
        icon: <AssignmentIcon />,
        url: routes.admin.tasks.list,
        apiUrl: apiRoutes.tasks.list,
      },
      {
        title: 'SIDEBAR.TASKS',
        icon: <AssignmentIcon />,
        url: routes.admin.clientTasks.list,
        apiUrl: apiRoutes.tasks.kanbanClient,
      },
      {
        title: 'SIDEBAR.CLIENTS',
        icon: <PeopleIcon />,
        url: routes.admin.clients.list,
        apiUrl: apiRoutes.clients.list,
      },
      {
        title: 'SIDEBAR.PROJECTS',
        icon: <AccountTreeIcon />,
        url: routes.admin.projects.list,
        apiUrl: apiRoutes.projects.list,
      },
      {
        title: 'SIDEBAR.EMPLOYEES',
        icon: <BadgeIcon />,
        url: routes.admin.employees.list,
        apiUrl: apiRoutes.employees.list,
      },
      {
        title: 'SIDEBAR.INVOICES',
        icon: <ReceiptIcon />,
        url: routes.admin.invoices.list,
        apiUrl: apiRoutes.invoices.list,
      },
      {
        title: 'SIDEBAR.WORK_LOGS',
        icon: <AccessTimeIcon />,
        url: routes.admin.taskWorkLogs.list,
        apiUrl: apiRoutes.taskWorkLogs.list,
      },
    ],
  },
  {
    title: 'SIDEBAR.GROUP_SETTINGS',
    items: [
      {
        title: 'SIDEBAR.USERS',
        icon: <AccountBox />,
        url: routes.admin.users.list,
        apiUrl: apiRoutes.users.list,
      },
      {
        title: 'SIDEBAR.TRANSLATIONS',
        icon: <SpellcheckIcon />,
        url: routes.admin.translations.list,
        apiUrl: apiRoutes.translations.list,
      },
    ],
  },
];
