import axios, { InternalAxiosRequestConfig } from 'axios';
import {
  getLocalStorageValue,
  LocalStorageKey,
  setLocalStorageValue,
} from '../../utils/localStorage/localStorage';
import { TokenModel } from '../../domain/Auth';
import { jwtDecode } from 'jwt-decode';
import { apiRoutes } from '../../../config/api/apiRoutes';

export type SuccessResponse<T> = {
  data: T;
};

export type ErrorResponse = {
  response: {
    data: {
      message: string;
      statusCode: number;
    };
  };
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(async (config) => {
  await appendAuthorizationToken(config);
  await appendSelectedCompany(config);
  await appendSelectedLanguage(config);

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const isTokenExpiringSoon = (token: string): boolean => {
  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000;

  if (!decodedToken || !decodedToken.exp) {
    return true;
  }

  return decodedToken.exp - currentTime < 3600;
};

const refreshToken = async (
  tokenModel: TokenModel,
): Promise<TokenModel | null> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/${apiRoutes.auth.refreshToken}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${tokenModel.token}`,
        },
      },
    );

    setLocalStorageValue(LocalStorageKey.token, response.data);
    return response.data;
  } catch (error) {
    return null;
  }
};

const appendAuthorizationToken = async (config: InternalAxiosRequestConfig) => {
  const tokenModel = getLocalStorageValue<TokenModel>(LocalStorageKey.token);

  if (!tokenModel) {
    return;
  }

  if (!config.headers) {
    return;
  }

  if (isTokenExpiringSoon(tokenModel.token)) {
    config.headers['Authorization'] = `Bearer ${
      (await refreshToken(tokenModel))?.token
    }`;
  } else {
    config.headers['Authorization'] = `Bearer ${tokenModel.token}`;
  }
};

const appendSelectedCompany = async (config: InternalAxiosRequestConfig) => {
  const selectedCompany = getLocalStorageValue<TokenModel>(
    LocalStorageKey.company,
  );

  if (!selectedCompany) {
    return;
  }

  if (!config.headers) {
    return;
  }

  config.headers['Company'] = selectedCompany;
};

const appendSelectedLanguage = async (config: InternalAxiosRequestConfig) => {
  const selectedLanguage = getLocalStorageValue<TokenModel>(
    LocalStorageKey.language,
  );

  if (!selectedLanguage) {
    return;
  }

  if (!config.headers) {
    return;
  }

  config.headers['Language'] = selectedLanguage;
};

export default instance;
