import React from 'react';
import AdminNavigation from './AdminNavigation/AdminNavigation';
import { Box, IconButton as MuiIconButton } from '@mui/material';
import styles from './AdminLayout.module.scss';
import { Outlet } from 'react-router-dom';
import AdminSidebar from './AdminSidebar/AdminSidebar';
import { useRecoilState } from 'recoil';
import { sidebarExtendedState } from '../../atoms/layout/layout.atom';
import cx from 'classnames';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { useWindowWidth } from '@react-hook/window-size';
import { colors } from '../../styles/colors';

export const AdminLayout = () => {
  const [sidebarExtendedValue, setSidebarExtendedValue] =
    useRecoilState(sidebarExtendedState);
  const screenWidth = useWindowWidth();

  return (
    <>
      <AdminNavigation />
      <Box className={styles.mainBoxContainerForPage}>
        {screenWidth >= 1200 && (
          <Box
            className={cx(styles.mainBoxContainerForSidebar, {
              [styles.collapsed]: !sidebarExtendedValue,
            })}
          >
            <MuiIconButton
              onClick={() => setSidebarExtendedValue(!sidebarExtendedValue)}
              size="small"
              className={styles.sidebarExpandButton}
              style={{
                color: colors.textSubtitleColor,
                borderColor: colors.dividerBorderColor,
                backgroundColor: colors.sidebarExpandColor,
              }}
            >
              {sidebarExtendedValue ? (
                <ChevronLeft fontSize="small" />
              ) : (
                <ChevronRight fontSize="small" />
              )}
            </MuiIconButton>
            <AdminSidebar />
          </Box>
        )}
        <Box
          component="main"
          className={cx(styles.mainContainer, {
            [styles.collapsedMainContainer]: !sidebarExtendedValue,
          })}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default AdminLayout;
