import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../atoms/translation/translation.atom';
import { translate } from '../../../utils/translation/translation';
import {
  clearLocalStorageValue,
  getLocalStorageValue,
  LocalStorageKey,
  setLocalStorageValue,
} from '../../../utils/localStorage/localStorage';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../config/routes/routes';
import ErrorFallback from '../../../common/ErrorFallback/ErrorFallback';

export const ErrorPage = () => {
  const translations = useRecoilValue(translationsState);
  const navigate = useNavigate();

  const [isRepeat] = useState(
    getLocalStorageValue(LocalStorageKey.errorRepeat) ?? false,
  );

  useEffect(() => {
    if (!isRepeat) {
      setLocalStorageValue(LocalStorageKey.errorRepeat, true);
      window.location.reload();
    } else {
      clearLocalStorageValue(LocalStorageKey.errorRepeat);
    }
  }, []);

  if (!isRepeat) {
    return null;
  }

  return (
    <ErrorFallback
      title={translate(translations, 'ERROR_PAGE.TITLE')}
      subtitle={translate(translations, 'ERROR_PAGE.SUBTITLE')}
      image={'https://ababa-tech.ams3.digitaloceanspaces.com/public/500.svg'}
      buttonText={translate(translations, 'ERROR_PAGE.GO_TO_HOME')}
      onButtonClick={() => navigate(routes.homepage)}
    />
  );
};

export default ErrorPage;
