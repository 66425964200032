import React, { ReactNode, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useMutation } from '@tanstack/react-query';
import type { DefaultError } from '@tanstack/query-core';
import axios, { SuccessResponse } from '../../libraries/axios/axios';
import {
  ProgressFactory,
  ProgressVariant,
} from '../../common/Progress/ProgressFactory';
import { apiRoutes } from '../../../config/api/apiRoutes';
import { translationsState } from '../../atoms/translation/translation.atom';
import { Language } from '../../domain/Language';
import { selectedLanguageState } from '../../atoms/language/language.atom';

type TranslationProviderProps = {
  children: ReactNode;
};

export const TranslationProvider = ({ children }: TranslationProviderProps) => {
  const [isInitCompleted, setIsInitCompleted] = useState(false);

  const setTranslationsStateValue = useSetRecoilState(translationsState);
  const selectedLanguage = useRecoilValue(selectedLanguageState);

  const { mutate, isPending } = useMutation<
    SuccessResponse<Language>,
    DefaultError
  >({
    mutationFn: () =>
      axios.get(
        apiRoutes.languages.locale.replace(':locale', selectedLanguage),
      ),
    onSuccess: (response) =>
      setTranslationsStateValue(response.data.translations),
    onSettled: () => setIsInitCompleted(true),
  });

  useEffect(() => {
    mutate();
  }, [selectedLanguage]);

  if (!isInitCompleted || isPending) {
    return <ProgressFactory variant={ProgressVariant.linear} />;
  }

  return <>{children}</>;
};

export default TranslationProvider;
