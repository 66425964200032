import { baseApiRoutes } from '../../core/config/apiRoutes';

export const apiRoutes = {
  ...baseApiRoutes,
  clients: {
    get: 'clients/:id',
    list: 'clients',
    create: 'clients',
    update: 'clients/:id',
    delete: 'clients/:id',
    options: 'clients/options',
  },
  projects: {
    get: 'projects/:id',
    list: 'projects',
    create: 'projects',
    update: 'projects/:id',
    delete: 'projects/:id',
    statusOptions: 'projects/status-options',
    allOptions: 'projects/options-all',
    options: 'projects/options',
  },
  clientRepresentatives: {
    get: 'client-representatives/:id',
    list: 'client-representatives',
    create: 'client-representatives',
    update: 'client-representatives/:id',
    delete: 'client-representatives/:id',
    rolesOptions: 'client-representatives/role-options',
    bulkDelete: 'client-representatives/bulk-delete',
  },
  employees: {
    get: 'employees/:id',
    list: 'employees',
    create: 'employees',
    update: 'employees/:id',
    delete: 'employees/:id',
    rolesOptions: 'employees/role-options',
    typesOptions: 'employees/type-options',
    options: 'employees/options',
    bulkDelete: 'employees/bulk-delete',
  },
  tasks: {
    get: 'tasks/:id',
    getForClient: 'tasks/:id/client',
    getTaskCommentsCount: 'tasks/:key/total-comments',
    kanban: 'tasks/kanban',
    kanbanClient: 'tasks/kanban-client',
    list: 'tasks',
    listActive: 'tasks/active',
    create: 'tasks',
    update: 'tasks/:id',
    updateMainInfo: 'tasks/:id/main-info',
    updateDetails: 'tasks/:id/details',
    updateAssignees: 'tasks/:id/assignees',
    updateAttachments: 'tasks/:id/attachments',
    updateType: 'tasks/:id/type',
    updateStatus: 'tasks/:id/status',
    updatePriority: 'tasks/:id/priority',
    updateOrigin: 'tasks/:id/origin',
    updateName: 'tasks/:id/name',
    updateDescription: 'tasks/:id/description',
    updateKanbanPosition: 'tasks/:id/position',
    delete: 'tasks/:id',
    typesOptions: 'tasks/type-options',
    prioritiesOptions: 'tasks/priority-options',
    originOptions: 'tasks/origin-options',
    statusOptions: 'tasks/status-options',
    activeStatusOptions: 'tasks/active-status-options',
    bulkDelete: 'tasks/bulk-delete',
  },
  taskComments: {
    create: 'task-comments',
    createForClient: 'task-comments/client',
    update: 'task-comments/:id',
    delete: 'task-comments/:id',
  },
  taskWorkLogs: {
    list: 'task-work-logs',
    create: 'task-work-logs',
    delete: 'task-work-logs/:id',
  },
  invoices: {
    get: 'invoices/:id',
    list: 'invoices',
    options: 'invoices/options',
    statusOptions: 'invoices/options/status',
    optionsForProject: 'invoices/options/:project',
    create: 'invoices',
    update: 'invoices/:id',
    updateStatus: 'invoices/:id/status',
    sendInvoice: 'invoices/:id/send-invoice',
    delete: 'invoices/:id',
  },
  invoiceItems: {
    get: 'invoice-items/:id',
    list: 'invoice-items',
    listByInvoice: 'invoice-items/invoice',
    create: 'invoice-items',
    update: 'invoice-items/:id',
    assignInvoice: 'invoice-items/:id/assign-invoice',
    assignInvoices: 'invoice-items/assign-invoices',
    updateName: 'invoice-items/:id/name',
    updateAmount: 'invoice-items/:id/amount',
    delete: 'invoice-items/:id',
    bulkDelete: 'invoice-items/bulk-delete',
  },
  documents: {
    get: 'documents/:id',
    list: 'documents',
    options: 'documents/options',
    create: 'documents',
    update: 'documents/:id',
    delete: 'documents/:id',
    bulkDelete: 'documents/bulk-delete',
  },
};
