import React from 'react';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../atoms/translation/translation.atom';
import { translate } from '../../../utils/translation/translation';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../config/routes/routes';
import ErrorFallback from '../../../common/ErrorFallback/ErrorFallback';

export const NotFoundPage = () => {
  const translations = useRecoilValue(translationsState);
  const navigate = useNavigate();

  return (
    <ErrorFallback
      title={translate(translations, 'NOT_FOUND_PAGE.TITLE')}
      subtitle={translate(translations, 'NOT_FOUND_PAGE.SUBTITLE')}
      image={'https://ababa-tech.ams3.digitaloceanspaces.com/public/404.svg'}
      buttonText={translate(translations, 'NOT_FOUND_PAGE.GO_TO_HOME')}
      onButtonClick={() => navigate(routes.homepage)}
    />
  );
};

export default NotFoundPage;
