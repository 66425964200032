import React, { useEffect, useState } from 'react';
import {
  IconButton as MuiIconButton,
  MenuItem,
  Popover,
  Stack,
} from '@mui/material';
import styles from './LanguagePopover.module.scss';
import {
  TCountryCode,
  countries,
  languages,
  TLanguageCode,
} from 'countries-list';
import { useMutation } from '@tanstack/react-query';
import { DefaultError } from '@tanstack/query-core/build/modern';
import axios from '../../../../libraries/axios/axios';
import { SelectOption } from '../../../../common/Input/SelectInput/SelectInput';
import { apiRoutes } from '../../../../../config/api/apiRoutes';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { selectedLanguageState } from '../../../../atoms/language/language.atom';
import cx from 'classnames';
import {
  LocalStorageKey,
  setLocalStorageValue,
} from '../../../../utils/localStorage/localStorage';
import { colors } from '../../../../styles/colors';
import ReactCountryFlag from 'react-country-flag';

export const LanguagePopover = () => {
  const [selectedLanguage, setSelectedLanguage] = useRecoilState(
    selectedLanguageState,
  );

  const [userAnchorEl, setUserAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const { mutate, data: response } = useMutation<
    { data: SelectOption[] },
    DefaultError
  >({
    mutationFn: () => {
      return axios.get(apiRoutes.languages.options);
    },
  });

  useEffect(() => {
    mutate();
  }, []);

  const handleOpenPopover = (e: React.MouseEvent<HTMLButtonElement>) => {
    setUserAnchorEl(e.currentTarget);
    setIsOpen(true);
  };

  const handleClosePopover = () => {
    setIsOpen(false);
    setUserAnchorEl(null);
  };

  const handleLanguageSelect = (languageOption: SelectOption) => {
    setSelectedLanguage(languageOption.label);
    setLocalStorageValue(LocalStorageKey.language, languageOption.label);
    handleClosePopover();
  };

  const getLanguageItem = (languageOption: SelectOption) => {
    const country = Object.keys(countries).find(
      (countryKey) =>
        countryKey.toLowerCase() === languageOption.label.toLowerCase(),
    );

    const language = Object.keys(languages).find(
      (languageKey) =>
        languageKey === _.first(countries[country as TCountryCode].languages),
    );

    return (
      <>
        <ReactCountryFlag
          className={styles.countryFlag}
          countryCode={languageOption.label}
          svg
        />
        <span className={styles.countryName}>
          {languages[language as TLanguageCode]?.native}
        </span>
      </>
    );
  };

  return (
    <>
      <MuiIconButton
        onClick={(e) => handleOpenPopover(e)}
        size="small"
        className={styles.languageButton}
      >
        <ReactCountryFlag
          style={{ color: colors.textSubtitleColor }}
          countryCode={selectedLanguage}
          svg
        />
      </MuiIconButton>
      <Popover
        open={isOpen}
        anchorEl={userAnchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <>
          <span
            className={styles.popoverArrow}
            style={{
              borderColor: colors.popoverArrowBorderColor,
              backgroundColor: colors.popoverArrowBackgroundColor,
            }}
          />
          <Stack p={'4px'}>
            {response?.data.map((languageOption) => (
              <MenuItem
                key={languageOption.value}
                className={cx(styles.languageItem, {
                  [styles.isSelected]:
                    languageOption.label === selectedLanguage,
                })}
                onClick={() => handleLanguageSelect(languageOption)}
              >
                {getLanguageItem(languageOption)}
              </MenuItem>
            ))}
          </Stack>
        </>
      </Popover>
    </>
  );
};

export default LanguagePopover;
